'use client';

import { ContentAiSearchTeaserBasicAttributes } from '@/src/lib/strapi/strapi-model.ts';
import { canHaveSection } from '@/src/components/strapi/StrapiSection.tsx';
import DiscoveryTeaser from '@/src/components/discovery/DiscoveryTeaser.tsx';
import arrow from '@/public/images/arrow.svg';
import { ReactComponent as InstagramStack } from '@/public/images/instagram-stack.svg';
import Image from 'next/image';
import { Zap } from 'lucide-react';
import { useTranslations } from 'next-intl';

export default function StrapiContentAiSearchTeaserBasic({
  attributes,
}: {
  attributes: ContentAiSearchTeaserBasicAttributes;
}) {
  const trans = useTranslations();

  return canHaveSection(attributes.section)(
    <div className="w-full p-8">
      <div className="grid grid-cols-1 gap-12 lg:grid-cols-2 lg:gap-4">
        <div className="flex flex-col justify-center">
          <p className="text-foreground/80 mb-4 text-xl font-medium">
            <Zap className="text-secondary mr-4  inline" size={48} />
            {trans('STRAPI.CONTENT_AI_SEARCH_TEASER.TEASE')}
          </p>
          <h2 className="text-foreground mb-5 text-6xl font-bold">
            {attributes.head}
          </h2>
          <p className="text-foreground/65 dark:text-foreground/60 text-xl">
            {attributes.sub_head}
          </p>
        </div>
        <div className="flex flex-row items-center justify-center">
          <div className="w-full lg:w-2/3">
            <InstagramStack className="mx-auto mb-6 w-1/2" />
            <div className="relative">
              <Image
                src={arrow}
                width={100}
                height={100}
                alt="arrow"
                className="absolute -bottom-[180px] right-0 size-[120px] rotate-[170deg] brightness-200 lg:-top-[130px] lg:left-0 lg:-rotate-12"
              />
            </div>
            <DiscoveryTeaser samples={attributes.samples?.split(';')} />
          </div>
        </div>
      </div>
    </div>,
  );
}
